import React from 'react'
import './processCardStyles.css'

export default function ProcessCard(props) {

  const processImageStyle = {
    backgroundImage: `url(${props.img})`
  }
  

    return (
      <div className="process-card">
        <div>
          <span>{props.step}</span>
          <h4>{props.title}</h4>
        </div>
        <div style={processImageStyle}></div>
      </div>
    );
}
