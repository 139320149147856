import {atom} from "recoil";

export const testDataAtom = atom({
    key: "test-builder-data",
    default: [
        // {
        //     id: '1',
        //     name: 'Anne Pro 2',
        //     price: '98.74',
        //     discount: '12',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '2',
        //     name: 'Keychron K2',
        //     price: '80',
        //     discount: '43',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '3',
        //     name: 'GMK Pro',
        //     price: '98.74',
        //     discount: '25',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '4',
        //     name: 'Patje keyboard',
        //     price: '80',
        //     discount: '34',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '5',
        //     name: 'Keychron K14',
        //     price: '34',
        //     discount: '55',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'

        // },
        // {
        //     id: '6',
        //     name: 'Ducky 2 mini',
        //     price: '43',
        //     discount: '43',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '7',
        //     name: 'Logitech stealth',
        //     price: '123',
        //     discount: '22',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '8',
        //     name: 'Rzer Seiren Mini',
        //     price: '983',
        //     discount: '5',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '9',
        //     name: 'Kello Kitty Slayer',
        //     price: '62',
        //     discount: '1',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
        // {
        //     id: '10',
        //     name: 'Batu gay',
        //     price: '69.96',
        //     discount: '5',
        //     img: 'https://cdn.shopify.com/s/files/1/2436/9621/products/keychron-k6_02_800x800.jpg',
        //     rating: '4.5',
        //     buyLink: 'https://www.google.com'
        // },
    ],
});

export const loaderTestData = atom({
    key: 'loaderTestData',
    default: true
})