import {atom, selector} from "recoil";
import {recoilPersist} from "recoil-persist"


// recoil persist setup

const { persistAtom } = recoilPersist({
  key: 'persist-custom',
  storage: localStorage,
})


// Default Atom Object

const defaultObj = {
    name: '',
    price: 0,
    original_price: 0,
    discount: '',
    img: '',
    buyLink: '',
    category: '',
    latest_volume: '',
    details: {
      Size: '',
      Notes: '',
    },
    description: 'No text available',
}


// custom atoms

export const getCustomAtom = (key) => {
  return atom({
    key: key,
    default: defaultObj,
    effects_UNSTABLE: [persistAtom],
  });
}


export const customBoardAtom = getCustomAtom("customBoardState")

export const customSwitchesAtom = getCustomAtom("customSwitchesState")
export const customKeycapsAtom = getCustomAtom("customKeycapsState")
export const customLubricationAtom = getCustomAtom("customLubricationState")
export const customStabilizersAtom = getCustomAtom("customStabilizersState")
export const customOringsAtom = getCustomAtom("customOringsState")
export const customSwitchFilmsAtom = getCustomAtom("customSwitchFilmsAtom")
export const customCableAtom = getCustomAtom("customCableState")
export const customSwitchTesterAtom = getCustomAtom("customSwitchTesterState")
export const customKeycapPullerAtom = getCustomAtom("customKeycapPullerState")
export const customSwitchOpenerAtom = getCustomAtom("customSwitchOpenerState")
export const customSwitchHolderAtom = getCustomAtom("customSwitchHolderState")
export const customDeskmatsAtom = getCustomAtom("customDeskmatsState")
export const customWristRestsAtom = getCustomAtom("customWristRestsState")


// end atoms

export const learnMoreAtom = atom({
  key: "learnMoreState",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const priceTotalAtom = selector({
  key: 'custom-price-total',
  get: ({ get }) => {

    const boardTotal = parseFloat(get(customBoardAtom).price)
    const switchesTotal = parseFloat(get(customSwitchesAtom).price)
    const keycapsTotal = parseFloat(get(customKeycapsAtom).price)
    const lubricationTotal = parseFloat(get(customLubricationAtom).price)
    const stabilizersTotal = parseFloat(get(customStabilizersAtom).price)
    const oRingsTotal = parseFloat(get(customOringsAtom).price)
    const switchFilmsTotal = parseFloat(get(customSwitchFilmsAtom).price)

    const cableTotal = parseFloat(get(customCableAtom).price)
    const switchTesterTotal = parseFloat(get(customSwitchTesterAtom).price)
    const keycapPullerTotal = parseFloat(get(customKeycapPullerAtom).price)
    const switchOpenerTotal = parseFloat( get(customSwitchOpenerAtom).price)
    const switchHolderTotal = parseFloat(get(customSwitchHolderAtom).price)
    const deskmatsTotal = parseFloat(get(customDeskmatsAtom).price)
    const wristRestsTotal = parseFloat(get(customWristRestsAtom).price)

    const total = [boardTotal, switchesTotal, keycapsTotal, lubricationTotal, stabilizersTotal, oRingsTotal, switchFilmsTotal, cableTotal, switchTesterTotal, keycapPullerTotal, switchOpenerTotal, switchHolderTotal, deskmatsTotal, wristRestsTotal].reduce((result, number) => result + number)

    return total
  }
})