

// Helper functions

export function RetrieveProductInfo(props) {
      const productObject = {
        name: props.title,
        price: props.price,
        original_price: props.originalPrice,
        discount: props.discount,
        img: props.img,
        buyLink: props.buyLink,
        rating: props.rating,
        category: props.category,
        latestVolume: props.latestVolume,
      }
      console.log(JSON.stringify(productObject));
      return productObject;
    }
