import React, {useEffect, useRef} from "react";
import '../../App.css';
import '../../styles/pageTemplatesStyles.css'
import './builderPageStyles.css'

import PreBuiltBuilder from './prebuiltBuilderPage';
import CustomBuilder from './customBuilderPage';

import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {initBuilderAtom} from '../../recoil/atoms/intializeBuilder'
import SumBuilder from "./components/sum-component/sumBuilder";
import {loaderTestData, testDataAtom} from "../../recoil/atoms/testDataBuilder";

function BuilderPage() {

    const builderId = useRecoilValue(initBuilderAtom)
    const setToggle = useSetRecoilState(initBuilderAtom)

    const preRef = useRef();
    const customRef = useRef();

    function togglePreBuilder() {
        setToggle(preRef.current.id)
    }

    function toggleCustomBuilder() {
        setToggle(customRef.current.id)
    }


    //  call products in this component then filter products from this call
    const pushModalInfoToRecoil = useSetRecoilState(testDataAtom)
    const [loaderstate, setLoaderState] = useRecoilState(loaderTestData)

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_LINK}/v1/products`)
            const json = await response.json()
            console.log(json.data)
            pushModalInfoToRecoil(json.data)
            setLoaderState(false)
        } catch (error) {
            console.log('something went wrong when trying to get the products')
        }
    }


    return (
        <div
            className='wrapper template-padding'
            style={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <div className='wrapped-container builder-container'>
                <article>
                    <h1>Keyboard Builder</h1>
                    <div className='switcher'>
                        <div
                            className={builderId === 'pre' ? 'active' : ''}
                            ref={preRef}
                            id='pre'
                            onClick={togglePreBuilder}
                        >
                            <span>casual</span>
                            <span>Pre-built</span>
                        </div>
                        <div
                            className={builderId === 'custom' ? 'active' : ''}
                            ref={customRef}
                            id='custom'
                            onClick={toggleCustomBuilder}
                        >
                            <span>intermediate</span>
                            <span>Custom build</span>
                        </div>
                    </div>
                    {builderId === 'pre' ? (
                        <PreBuiltBuilder />
                    ) : builderId === 'custom' ? (
                        <CustomBuilder />
                    ) : null}
                </article>
            </div>
            <SumBuilder />
        </div >
    );
}


export default BuilderPage;