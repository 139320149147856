import React from "react";
import "../../App.css";
import "../guide/guidecomponents/GuidePageCard";
import "../guide/guidecomponents/GuidePageCardStyles.css";
import "../../styles/pageTemplatesStyles.css";
import GeneralGuideImage from "../../assets/images/Guides_Board.jpg";
import BoardGuideImage from "../../assets/images/BoardGuide.png";
import SwitchGuideImage from "../../assets/images/SwitchesGuideImage.jpg";
import KeycapGuideImage from "../../assets/images/KeycapsGuideImage.jpg";
import AccessoiresGuideImage from "../../assets/images/accessoiresGuideImage.jpg";
import ModsGuideImage from "../../assets/images/ModsGuideImage.jpg";
import {Steps, Timeline} from "antd";
import {BrowserRouter as Router, useMatch,} from "react-router-dom";

import GuidePageCard from "./guidecomponents/GuidePageCard";

function GuidePage() {
  const match = useMatch();
  const { Step } = Steps;
  return (
    <Router>
      <div className="wrapper template-padding">
        <div className="wrapped-container">
          <section className="home-section-2">
            <div>
              <h2>Guides</h2>
              <div className="guide-section">
                <GuidePageCard
                  steps="General Guide"
                  title="Introduction to mechanical keyboards"
                  bodyText="In this guide, u can find everything about the basics of building your own mechanical keyboard."
                  imageLink={GeneralGuideImage}
                  buttonText="Learn more"
                />
                <div className="guide-extra">
                  <div>
                    <Timeline>
                      <Timeline.Item color="#2D62ED" dot="">
                        <GuidePageCard
                          steps="Components"
                          title="Boards"
                          bodyText="Your board is the base layer. It is the brain and skeleton of a mechanical keyboard."
                          imageLink={BoardGuideImage}
                          buttonText="Learn more"
                        />
                      </Timeline.Item>
                      <Timeline.Item>
                        <GuidePageCard
                          steps="Components"
                          title="Switches"
                          bodyText="Switches and Stabilisers are the component in your mechanical keyboard that sit underneath the keycaps."
                          imageLink={SwitchGuideImage}
                          buttonText="Learn more"
                        />
                      </Timeline.Item>
                      <Timeline.Item>
                        <GuidePageCard
                          steps="Components"
                          title="Keycaps"
                          bodyText="Keycaps are the plastics caps that houses the switches. They are the component you type on and  the main aesthetic part of you keyboard."
                          imageLink={KeycapGuideImage}
                          buttonText="Learn more"
                        />
                      </Timeline.Item>
                    </Timeline>
                  </div>
                </div>
                <GuidePageCard
                  steps="Guide"
                  title="Mods"
                  bodyText="In this guide, u can find everything about the ways to mod the mechanical keyboard."
                  imageLink={ModsGuideImage}
                  buttonText="Learn more"
                />
                <GuidePageCard
                  steps="Guide"
                  title="Accessoires"
                  bodyText="In this guide, u can find everything about the different accessoires of mechanical keyboards."
                  imageLink={AccessoiresGuideImage}
                  buttonText="Learn more"
                />
              </div>
            </div>
            {/* <h1>Guides</h1>
            <p>Learn more about</p>
            <Link to={`${match.url}/switches`}>Switches</Link>
            <Switch>
              <Route
                path={`${match.path}/switches`}
                component={SwitchGuidePage}
              ></Route>
              <Route path={`${match.path}`}>
                <h3>Please select a topic</h3>
                <div>
                  <Button type="text">aang is cute</Button>
                </div>
              </Route>
            </Switch> */}
          </section>
        </div>
      </div>
    </Router>
  );
}

export default GuidePage;
