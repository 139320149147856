import React from 'react';
import './notFoundStyles.css';

import {Link} from 'react-router-dom';

import {Button} from 'antd';
import {FrownFilled, HomeFilled} from '@ant-design/icons'

function NotFound() {
    return (
        <div className='wrapper'>
            <div className='notfound-content'>
                <FrownFilled spin='true' style={{ fontSize: '56px' }} />
                <h1>Page Not Found</h1>
                <p>Sorry, we can't find the page you're looking for.</p>
                <p>Visit our homepage or contact support.</p>
                <Link to='/'>
                    <Button type='primary' icon={<HomeFilled />}>Go to homepage</Button>
                </Link>
            </div>
        </div>
    )
}

export default NotFound;
