import React, {useContext, useEffect, useState} from 'react'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    // const [user, setUser] = useState()
    const [authed, setAuthed] = useState(false)
    const credentials = JSON.parse(localStorage.getItem('userObj'));


    useEffect(() => {
        async function autoLogin() {
            const token = localStorage.getItem("token")

            if (token) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_LINK}/v1/auto_login`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    const json = await response.json()
                    if (json === undefined) {
                        throw json
                    }
                    // setUser(json)
                    localStorage.setItem("userObj", JSON.stringify(json))
                    setAuthed(true)
                    console.log(json)
                } catch (error) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('userObj')
                    console.log('Something went wrong, please try later')
                    window.location.reload()
                }
            } else {
                console.log('Context detected no user logged in ')
            }
        }
        autoLogin()
    }, [])


    // useEffect(() => {
    //     const token = localStorage.getItem("token")
    //     fetch(`${process.env.REACT_APP_API_LINK}/v1/user_is_authed`, {
    //         headers: {
    //             "Authorization": `Bearer ${token}`
    //         }
    //     })
    //         .then(resp => resp.json())
    //         .then(data => {
    //             if (data === undefined) {
    //                 throw data
    //             }
    //             console.log(data)
    //         }).catch(error => {
    //             console.log('Something went wrong, try again later');    //         })
    // }, [])

    const value = {
        // user,
        credentials,
        authed,
        setAuthed
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
