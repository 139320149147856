import React from "react";
import "./GuidePageCardStyles.css";
import {Button} from "antd";
import ChevronRight from "../../../assets/icons/ChevronRight";

function GuidePageCard(props) {
  const guideImageStyle = {
    background: `url(${props.imageLink})`,
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="guide-page-card">
      <div>
        <div className="guide-page-card-page-content">
          <span>{props.steps}</span>
          <h3>{props.title}</h3>
          <p>{props.bodyText}</p>
          <Button type="default" shape="round" value="large">
            {props.buttonText}
            {<ChevronRight />}
          </Button>
        </div>
        <div className="guide-page-card-image" style={guideImageStyle}></div>
      </div>
    </div>
  );
}

export default GuidePageCard;
