import React from 'react'
import './authStyles.css'
import buildCtaExampleImage from '../../assets/images/Build_CTA.png'
import authPattern from '../../assets/images/Pattern.png'

export default function AuthBanner() {
    return (
        <div className='auth-banner'>
            <img
                src={authPattern}
                alt='pattern-login'
                className='auth-pattern'
            >
            </img>
            <div>
                <h3>Building Keyboards made easy</h3>
                <p>Saving your build for later? Get inspired by the community of keyboard enthusiasts or share your build!
                Start creating with an account!
                    </p>
                <img
                    src={buildCtaExampleImage}
                    alt='highlight-builder'
                    style={{
                        width: '100%',
                    }}
                ></img>
            </div>
            <img
                src={authPattern}
                alt='pattern-login'
                className='auth-pattern'
            >
            </img>
        </div>
    )
}
