import React from 'react'
import './BuildCardStyles.css';
import {Link} from 'react-router-dom'
import {Button} from 'antd'
import {useSetRecoilState} from 'recoil'
import {initBuilderAtom} from '../../../recoil/atoms/intializeBuilder'
import ChevronRight from '../../../assets/icons/ChevronRight';

export default function Builds(props) {

    const setInitBuilder = useSetRecoilState(initBuilderAtom)

    const buildImageStylePre = {
        background: `url(${props.imageLink})`,
        backgroundSize: '500px',
        backgroundPosition: '0px',
        backgroundRepeat: 'no-repeat'
    }

    const buildImageStyleCustom = {
      background: `url(${props.imageLink})`,
      backgroundSize: "500px",
      backgroundPosition: "0px",
      backgroundRepeat: "no-repeat",
    };

    function goToBuilder() {
        setInitBuilder(() => {
            const builderId = props.builderInitialId
            // console.log('Builds: ' + builderId)
            return builderId
        })
    }

    return (
      <div className="build-card">
        <div
          className="build-card-image"
          style={
            props.builderInitialId === "pre"
              ? buildImageStylePre
              : buildImageStyleCustom
          }
        ></div>
        <div className="build-card-content">
          <span>{props.span}</span>
          <h3>{props.title}</h3>
          <p>{props.bodyText}</p>
          <Button
            type="primary"
            shape="round"
            value="large"
            onClick={goToBuilder}
          >
            <Link to="/builder">
              {props.buttonText}
            </Link>
            {<ChevronRight/>}
          </Button>
        </div>
      </div>
    );
}
